






















import { ColumnConfig } from '@/components/table/types'
import { Vue, Component } from 'vue-property-decorator'
import viewmore from './viewmore.vue'
@Component({
  components: {
    viewmore
  }
})
export default class feedback extends Vue {
  loading = false;
  tableData = [];
  dialogVisible = false;
  moreData: any = {};
  total = 0;
  current = 1;
  // formInline = {
  //   type: ''
  // };

  column: Array<ColumnConfig> = [
    {
      label: '举报用户名',
      code: 'createUser_richObj'
    },
    {
      label: '被举报用户',
      code: 'complaintUserId_richObj'
    },

    {
      label: '举报原因',
      code: 'content',
      scopedSlots: 'content'
    },
    {
      label: '举报时间',
      code: 'complaintTime'
    },
    {
      label: '操作',
      key: 'action',
      options: [
        {
          title: '详细',
          click: (row: any) => {
            this.showDialog(row.id)
          }
        },
        {
          title: '通过',
          click: (row: any) => {
            this.updateCommunity(row.id, 1)
          }
        },
        {
          title: '驳回',
          click: (row: any) => {
            this.updateCommunity(row.id, 2)
          }
        }
      ]
    }
  ];

  ellipsisStr<T> (str: T, limit = 15): T | string {
    if (typeof str === 'string' && str && str.length > limit) {
      return str.substring(0, limit) + '...'
    } else {
      return str
    }
  }

  async showDialog (id: number) {
    const res = await this.$Get(this.$Api.complaintDetail, {
      id: id
    })
    this.moreData = res.data
    this.dialogVisible = true
  }

  async updateCommunity (id: number, type: number) {
    await this.$Post(this.$Api.updateComplaint, {
      id,
      type
    })
    this.$message.success('处理成功')
    this.fetch()
  }

  changePagination (current: number) {
    this.current = current
    this.fetch()
  }

  async fetch () {
    this.loading = true
    const res = await this.$Get(this.$Api.complaintListPage, {
      current: this.current,
      pageSize: 10
    })

    this.tableData = res.data.records
    this.total = res.data.total
    this.loading = false
  }

  mounted () {
    this.fetch()
  }
}
